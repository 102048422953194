<template>
  <div>
    <section class="empty-section section-t-space section-b-space">
        <div class="custom-container">
          <h2 class="text-start" style="font-size: 2rem;">Flavors at your fingertips</h2>
          <h5>Crafted with Love, served with pride.</h5>
        </div>
        <div class="empty-spacer"></div>
    </section>
  </div>
</template>

<script>
export default {
    name : 'FooterVue'
}
</script>

<style>
.empty-spacer{
  width: 30px;
  height: 80px;
}
</style>